import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Button } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
  })
);

export const OrderCancelled = (props: { orderId: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { orderId } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid alignItems="center">
        <Typography variant="h5" gutterBottom>
          {t(`The order #${orderId} has been cancelled.`)}
        </Typography>
      </Grid>
      <Link to={"/orders"} className={classes.link}>
        <Button variant="outlined" color="primary">
          {t("Back to orders")}
        </Button>
      </Link>
    </Grid>
  );
};
