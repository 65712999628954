import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
  })
);

export const OrderRejected = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CheckCircleIcon
        className={classes.icon}
        color="primary"
        fontSize="medium"
      />
      <Grid item>
        <Typography variant="h5" align="center" gutterBottom>
          {t("You have successfully rejected the order.")}
        </Typography>
      </Grid>
    </Grid>
  );
};
