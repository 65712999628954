import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

//Queries
import { useQuery } from "@apollo/client";

// Components
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { graphql } from "../../../gql";

export const getCompanyByIdQuery = graphql(/* GraphQL */ `
  query getCompanyById($uuid: UUID!) {
    company(uuid: $uuid) {
      uuid
      name
      identifier
      vat
      canOrder
    }
  }
`);

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
  });
});

export const CompanyInfo = (props: { companyId: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { companyId } = props;

  const { data, loading, error } = useQuery(getCompanyByIdQuery, {
    variables: { uuid: companyId },
  });

  if (error) throw error;

  if (loading) {
    return <LoadingIndicator />;
  }
  if (!data?.company) throw Error;

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Company Information")}</Typography>
      <Grid
        container
        direction="row"
        style={{ height: "100%" }}
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={4}>
          <Typography variant="overline">{t("Company Name")}</Typography>
          <Typography variant="h5" component="h3">
            {data && data.company.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="overline">{t("Company VAT")}</Typography>
          <Typography variant="h5" component="h3">
            {data && data.company.vat}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
