import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../Components/PageContainer";

import { ApprovalPage } from "./pages/ApprovalPage";

const ApprovalContainer = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/order_approval/:orderId"}
        render={(props) => <ApprovalPage {...props} />}
      />
    </Switch>
  );
};

interface ApprovalModule {
  (props: Object): JSX.Element;
}

export const Approval: ApprovalModule = (props) => {
  const { t } = useTranslation();

  return (
    <PageContainer title={t("Approve Order")} subTitle={""}>
      <ApprovalContainer />
    </PageContainer>
  );
};
