import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Grid, Button, Typography } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
  })
);

export const ServerError = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h3" gutterBottom>
        {t("Internal Server Error")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t(
          "The server encountered an internal error and was unable to complete your request."
        )}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("Please contact the customer service.")}
      </Typography>

      <Link to={"/info_center/contact"} className={classes.link}>
        <Button variant="outlined" color="primary">
          {t("Contact Us")}
        </Button>
      </Link>
    </Grid>
  );
};
