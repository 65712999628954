import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

// Components
import { AddressItem } from "../../../Components/AddressItem";

// Utils
import { getMPTypes, getTotalLabels } from "../../../Utils/markingProduct";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { orderFragment } from "../../History/pages/OrderDetail";
import { FragmentType, getFragmentData } from "../../../gql";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
  });
});

export const OrderInfo = (props: {
  order: FragmentType<typeof orderFragment>;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  // Reading fragment data
  const order = getFragmentData(orderFragment, props.order);

  const methods = new Map<string, { name: string }>([
    ["SCRIBOS_HANDLED", { name: t("Organised by SCRIBOS") }],
    ["SELF_HANDLED", { name: t("Self-Organised") }],
  ]);

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Order Information")}</Typography>
      <Grid container direction="row" style={{ height: "100%" }} spacing={3}>
        <Grid item xs={12} sm={3}>
          <Typography variant="overline" style={{ marginRight: "8px" }}>
            {t("ORDER ID")}
          </Typography>
          <Typography variant="h5" component="h3">
            {`#${order.orderId}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="overline">{t("order date")}</Typography>
          <Typography variant="h5" component="h3">
            {order.dateCreated.split("T")[0]}
          </Typography>
        </Grid>

        {getMPTypes(props.order!).includes(ProductType.Physical) && (
          <Grid item xs={12} sm={3}>
            <Typography variant="overline">
              {t("desired delivery date")}
            </Typography>
            {order.requestedShippingDate ? (
              <Typography variant="h5" component="h3">
                {order.requestedShippingDate}
              </Typography>
            ) : (
              <Typography variant="body1" component="h3" color="textSecondary">
                {t("No Desired Date Indicated")}
              </Typography>
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={3} alignItems="center">
          <Typography variant="overline">{t("N° of Labels")}</Typography>
          <Typography variant="h5" component="h3">
            {getTotalLabels(props.order)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="overline">{t("Delivery Address")}</Typography>
          <AddressItem address={order.deliveryAddress} />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="overline">{t("Billing Address")}</Typography>
          <AddressItem address={order.billingAddress} />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Grid
            container
            style={{ height: "100%" }}
            direction="row"
            alignItems="stretch"
          >
            {getMPTypes(props.order!).includes(ProductType.Physical) && (
              <Grid item xs={12}>
                <Typography variant="overline">
                  {t("Transportation Method")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {order.transportationMethod
                    ? methods.has(order.transportationMethod)
                      ? methods.get(order.transportationMethod)?.name
                      : ""
                    : ""}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="overline">
                {t("Purchase Order Number")}
              </Typography>

              {order.purchaseOrderNumber ? (
                <Typography variant="body1" gutterBottom>
                  {order.purchaseOrderNumber}
                </Typography>
              ) : (
                <Typography variant="body1" gutterBottom color="textSecondary">
                  {t("No Order Number Indicated")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
