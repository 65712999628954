import React from "react";
import AppContainer from "../AppContainer";
import { FlagsProvider } from "../orderingFlags";

const FeaturesContainer = () => {
  return (
    <FlagsProvider
      flags={{
        allowedRegister: false,
        minimumOrder: false,
        volumeControl: { enabled: false },
        allowedCountries: [
          { code: "FR", name: "France" },
          { code: "ES", name: "Spain" },
          { code: "DE", name: "Germany" },
          { code: "IT", name: "Italy" },
          { code: "CZ", name: "Czech Republic" },
          { code: "SI", name: "Slovenia" },
          { code: "TR", name: "Turkey" },
          { code: "IN", name: "India" },
          { code: "CN", name: "China" },
          { code: "AR", name: "Argentina" },
          { code: "BR", name: "Brazil" },
        ],
        footers: {
          imprint: {
            markdown: false,
            links: {
              en: "https://www.fff.fr/455-mentions-legales.html",
              fr: "https://www.fff.fr/455-mentions-legales.html ",
            },
          },
          privacypolicy: {
            markdown: false,
            links: {
              en: "https://www.fff.fr/456-protection-des-donnees.html",
              fr: "https://www.fff.fr/456-protection-des-donnees.html",
            },
          },
          contact: {
            markdown: false,
            links: {
              en: "https://www.fff.fr/",
              fr: "https://www.fff.fr/",
            },
          },
        },
        companyProfile: {
          userPanel: false,
          nonEditableAddressCategories: [],
        },
      }}
    >
      <AppContainer />
    </FlagsProvider>
  );
};

export default FeaturesContainer;
