import React, { Fragment } from "react";
import { ModuleRoute } from "../../Components/ModuleRoute";
import { useTranslation } from "react-i18next";
import { Approval } from "./Approval";

type Props = {
  sortOrder: number;
};

export const ApprovalModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        showInMenu={false}
        name={"approval"}
        displayName={() => t("Approve Order")}
        path={"/order_approval"}
        routeParams={":orderId?"}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <Approval />}
        permissions={["can_approve_orders"]}
      />
    </Fragment>
  );
};
